import React, { useState, useEffect } from "react";

const EinsteinTile = ({className, mainColor = "#76A37D", secondaryColor = "#333334", randomizedCounter, resetCounter, style = {}}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    setIsFlipped(false);
  }, [resetCounter]);

  useEffect(() => {
    if (randomizedCounter > 0) {
        const randomBinary = Math.random() >= 0.5 ? 1 : 0;
        setIsFlipped(!!randomBinary);
    }
  }, [randomizedCounter]);

  let tileStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#555",
    cursor: "pointer",
  }
  
  return (
    <div className={className} style={{...tileStyle, ...style, backgroundColor: isFlipped ? secondaryColor : mainColor}} onClick={()=>setIsFlipped(!isFlipped)}/>
  )
};

export default EinsteinTile;