import React, {useState} from "react";
import Draggable from "react-draggable";
import { SketchPicker } from "react-color";

const TileMenu = ({ tileSize, mainColor, secondaryColor, backgroundColor, onMainColorChange, onSecondaryColorChange, onBackgroundColorChange, onTogglePicker, isPickerVisible, currentPicker, onClosePicker, onTileSizeChange, lineWidth, onLineWidthChange, mainNodeSize, onMainNodeSizeChange, nodeSize, onNodeSizeChange, isSideNodeToggled, onSideNodeToggleChange, isCornerNodeToggled, onCornerNodeToggleChange, onClickRandomize, onClickReset}) => {
    const [isMinBtnHovered, setIsMinBtnHovered] = useState(false); 
    const [isMenuMinimized, setIsMenuMinimized] = useState(false); 
    const [isCloseColorBtnHovered, setCloseColorBtnHovered] = useState(false); 
    const [isRandomizeBtnHovered, setRandomizeBtnHovered] = useState(false); 
    const [isResetBtnHovered, setResetBtnHovered] = useState(false); 
    
    const tileMenuContainerStyle = {
        position: "absolute",
        top: "5%",
        right: "7%",
        width: "300px",
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "10px 10px 33px -7px rgba(0,0,0,0.75)",
        WebkitBoxShadow: "10px 10px 33px -7px rgba(0,0,0,0.75)",
        MozBoxShadow: "10px 10px 33px -7px rgba(0,0,0,0.75)",
        zIndex: 1,
      };

    const menuForeheadStyle = {
    position: "relative",
    display: "flex",
    justifyContent: "left",
    width: "100%",
    backgroundColor: "#333",
    borderRadius: "8px 8px 0 0",
    color: "#fff",
    zIndex: 10,
    cursor: "move",
  }
  const menuTitleStyle = {
    fontSize: "0.8rem",
    fontFamily: "coolFont",
    padding: "5px 10px" ,
    color: "#fff",
  }
  const menuContentStyle = {
    width: "100%",
    textAlign: "center",
    padding: isMenuMinimized ? "10px 5px 10px 5px" : "20px 5px 50px 5px",
    zIndex: 5,
    height: isMenuMinimized ? "0px" : "380px",
    overflow: "hidden",
    transition: "0.3s ease-in-out",
    MozTransition: "0.3s ease-in-out",
    WebkitTransition: "0.3s ease-in-out",
    OTransition: "0.3s ease-in-out",
  }

  const listStyle = {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
  }
  const listItemStyle = {
    display: "flex",
    margin: "10px 0",
    padding: "0",
    height: "20px",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
  }
  const listItemStyle2 = {
    margin: "15px 0",
    flexDirection: "column",
  }
  const settingTextStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 15px" ,
    fontSize: "0.9rem",
  }
  const colorPreviewStyle = {
    position: "relative",
    border: "0px solid black",
    width: "50px",
    height: "100%",
    borderRadius: "8px",
    boxShadow: "0 0 5px 0 rgba(0,0,0,0.6)",
    cursor: "pointer",
  }
  const sliderStyle = {
    width: "80%",
    margin: "10px auto",
    cursor: "pointer",
  }

  const minBtnStyle = {
    position: "relative",
    display: "inline-block",
    width: "15px",
    height: "15px",
    backgroundColor: "#FFBD44",
    borderRadius: "50%",
    cursor: "pointer",
    margin: "12px 12px",
    opacity: isMinBtnHovered ? 0.8 : 1,
  }
  const minBtnDashStyle = {
    position: "absolute",
    display: "block",
    width: "70%",
    left: "15%",
    height: "2px",
    backgroundColor: "#333",
    margin: "7px auto",
    opacity: 0.5,
  }
  
  const tileMenuColorPickerContainerStyle = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#eee",
    padding: "25px 0",
    top: "40px",
    left: 0,
    opacity: 0,
    marginLeft: 0,
    width: "300px",
    textAlign: "center",
    alignItems: "center",
    borderRadius: "8px 0 0 8px",
    transition: "all 0.3s ease-in-out, opacity 1s ease-in-out, opacity 0.3s ease-out",
    MozTransition: "all 0.3s ease-in-out, opacity 1s ease-in, opacity 0.3s ease-out",
    WebkitTransition: "all 0.3s ease-in-out, opacity 1s ease-in, opacity 0.3s ease-out",
    OTransition: "all 0.3s ease-in-out, opacity 1s ease-in, opacity 0.3s ease-out",
    zIndex: -2,
  };
  
  const tileMenuColorPickerContainerButtonStyle = {
    margin: "15px 0 0 0",
    padding: "5px",
    borderColor: "transparent",
    backgroundColor: "transparent",
    color: "#333",
    fontSize: "1rem",
    cursor: "pointer",
    transition: "0.3s ease-in-out",
    MozTransition: "0.3s ease-in-out",
    WebkitTransition: "0.3s ease-in-out",
    OTransition: "0.3s ease-in-out",
    opacity: isCloseColorBtnHovered ? 0.8 : 1,
    transform: isCloseColorBtnHovered ? "scale(1.1)" : "scale(1)",
  };

  const listItemStyle3 = {
    display: "flex",
    padding: "0",
    height: "30px",
    flexDirection: "row",
    justifyContent: "space-around",
  }

  const stdBtnStyle = {
    borderColor: "transparent",
    backgroundColor: "transparent",
    color: "#333",
    fontSize: "1rem",
    padding: "2px",
    cursor: "pointer",
    transition: "0.3s ease-in-out",
    MozTransition: "0.3s ease-in-out",
    WebkitTransition: "0.3s ease-in-out",
    OTransition: "0.3s ease-in-out",
  }

  return (
    <Draggable allowAnyClick={true} cancel=".no-drag">
      <div style={tileMenuContainerStyle}>
        <div 
            style={menuForeheadStyle}>
            <div style={minBtnStyle}
            onClick={() => setIsMenuMinimized(!isMenuMinimized)}
            onMouseEnter={() => setIsMinBtnHovered(true)} 
            onMouseLeave={() => setIsMinBtnHovered(false)} ><div style={minBtnDashStyle}></div></div>
          <h1 style={menuTitleStyle}>Einstein  Tile  Lab</h1>
        </div>
        
        <div className="no-drag" >
        <div style={menuContentStyle}>   
          <ul style={listStyle}>
            <li style={listItemStyle}>
              <p style={settingTextStyle}>Main Color</p>
              <div onClick={() => onTogglePicker('main')} style={{ ...colorPreviewStyle, backgroundColor: mainColor }} />
            </li>
            <li style={listItemStyle}>
              <p style={settingTextStyle}>Secondary Color</p>
              <div onClick={() => onTogglePicker('secondary')} style={{ ...colorPreviewStyle, backgroundColor: secondaryColor }} />
            </li>
            <li style={listItemStyle}>
              <p style={settingTextStyle}>Background Color</p>
              <div onClick={() => onTogglePicker('background')} style={{ ...colorPreviewStyle, backgroundColor: backgroundColor }}/>
            </li>
            <li style={{...listItemStyle, ...listItemStyle2}}>
              <p style={settingTextStyle}>Tile Size</p>
              <input type="range" min="50" max="500" value={tileSize} onChange={onTileSizeChange} style={sliderStyle} />
            </li>
            <li style={{...listItemStyle, ...listItemStyle2}}>
              <p style={settingTextStyle}>Line Width</p>
              <input type="range" min="0.0" max="7.0" value={lineWidth} onChange={onLineWidthChange} style={sliderStyle} />
            </li>
            <li style={{...listItemStyle, ...listItemStyle2}}>
              <p style={settingTextStyle}>Center Node Size</p>
              <input type="range" min="0.0" max={Math.max(50, tileSize*0.5)} value={mainNodeSize} onChange={onMainNodeSizeChange} style={sliderStyle} />
            </li>
            <li style={{...listItemStyle, ...listItemStyle2}}>
              <p style={settingTextStyle}>Node Size</p>
              <input type="range" min="0.0" max={Math.max(50, tileSize*0.5)} value={nodeSize} onChange={onNodeSizeChange} style={sliderStyle} />
            </li>
            <li style={listItemStyle}>
              <p style={settingTextStyle}>Toggle Nodes</p>
                <label className="switch" style={{marginRight: "10px"}}>
                <input type="checkbox" checked={isSideNodeToggled} onChange={onSideNodeToggleChange} />
                <span className="slider round"></span>
                </label>
                <label className="switch">
                <input type="checkbox" checked={isCornerNodeToggled} onChange={onCornerNodeToggleChange} />
                <span className="slider round"></span>
                </label>
            </li>
            <li style={{...listItemStyle, ...listItemStyle3}}>
                <button style={{...stdBtnStyle,
                opacity: isRandomizeBtnHovered ? 0.8 : 1,
                transform: isRandomizeBtnHovered ? "scale(1.1)" : "scale(1)",
                }} 
                onClick={onClickRandomize}
                onMouseEnter={() => setRandomizeBtnHovered(true)} 
                onMouseLeave={() => setRandomizeBtnHovered(false)}>Randomize</button>
                <button style={{...stdBtnStyle, 
                opacity: isResetBtnHovered ? 0.8 : 1,
                transform: isResetBtnHovered ? "scale(1.1)" : "scale(1)",
                }} 
                onClick={onClickReset}
                onMouseEnter={() => setResetBtnHovered(true)} 
                onMouseLeave={() => setResetBtnHovered(false)}>Reset</button>
            </li>
          </ul>
        </div>
        
        <div style={{ ...tileMenuColorPickerContainerStyle,
            width: isPickerVisible ? '300px' : '0', 
            opacity: isPickerVisible ? 1 : 0,
            marginLeft: isPickerVisible ? '-300px' : '0', 
            }}>
          <div style={{visibility: isPickerVisible ? 'visible' : 'hidden'}}>
          {currentPicker === 'main' && (
                <SketchPicker className="color-picker" color={mainColor} onChangeComplete={onMainColorChange} style={{opacity: isPickerVisible ? 1 : 0}}/>
              )}
              {currentPicker === 'secondary' && (
                <SketchPicker className="color-picker" color={secondaryColor} onChangeComplete={onSecondaryColorChange} />
              )}
              {currentPicker === 'background' && (
                <SketchPicker className="color-picker" color={backgroundColor} onChangeComplete={onBackgroundColorChange} />
              )}
              <button onMouseEnter={() => setCloseColorBtnHovered(true)} 
            onMouseLeave={() => setCloseColorBtnHovered(false)} style={tileMenuColorPickerContainerButtonStyle} onClick={onClosePicker}>Close</button>
              </div>
          </div>  
        </div>
      </div>
    </Draggable>
  );
};

export default TileMenu;