import React from "react";

const ContactBtn = ({ onClick, className, btnRef }) => {
  return (
    <button
      ref={btnRef}
      className={`contact-btn ${className}`}
      onClick={() => onClick()}
      aria-label="Kontakt"
    >
      Kontakt
    </button>
  );
};
export default ContactBtn;
