import React, { useState } from "react";
import { useWindowDimensions } from "../inc/WindowDimensions";

import EinsteinHexagon from "../components/einsteinTile/EinsteinHexagon";
import TileMenu from "../components/einsteinTile/TileMenu";

const angleToRad = (angle) => {
  return angle * Math.PI / 180;
}

class TileDimensions {
  constructor(size) {
    this.width = size;
    this.widthHalf = this.width / 2;
    this.widthEight = this.width / 8;
    this.widthQuarter = this.width / 4;
    this.widthThreeQuarter = this.width * 3 / 4;
    this.widthSevenEights = this.width * 7 / 8;

    this.height = (Math.cos(angleToRad(30)) * this.widthHalf) * 2;
    this.heightHalf = this.height / 2;
    this.heightEight = this.height / 8;
    this.heightQuarter = this.height / 4;
    this.heightThreeQuarter = this.height / 2;
    this.heightSevenEights = this.height * 7 / 8;

    this.yCornerTop = Math.cos(angleToRad(30)) * this.widthQuarter;
    this.yCornerBottom = this.height - this.yCornerTop;
  }
}

const EinsteinTileLab = () => {
  let winWidth = useWindowDimensions().width;
  let winHeight = useWindowDimensions().height;
  const [mainColor, setMainColor] = useState("#76A37D");
  const [secondaryColor, setSecondaryColor] = useState("#333334");
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [cachedBackgroundColor, setCachedBackgroundColor] = useState("#FFFFFF");
  const [tileSize, setTileSize] = useState(120);
  const [lineWidth, setLineWidth] = useState(1.5);
  const [mainNodeSize, setMainNodeSize] = useState(15);
  const [nodeSize, setNodeSize] = useState(10);
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [isSideNodeToggled, setIsSideNodeToggled] = useState(true);
  const [isCornerNodeToggled, setIsCornerNodeToggled] = useState(true);
  const [randomizedCounter, setRandomizedCounter] = useState(0);
  const [resetCounter, setResetCounter] = useState(0);
  const [currentPicker, setCurrentPicker] = useState('');
  const [isHelpBtnHovered, setIsHelpBtnHovered] = useState(false); 
  const [isHelpMenuShowing, setIsHelpMenuShowing] = useState(false);

  const handleMainColorChange = (color) => {
    setMainColor(color.hex);
  };

  const handleSecondaryColorChange = (color) => {
    setSecondaryColor(color.hex);
  };

  const handleBackgroundColorChange = (color) => {
    setBackgroundColor(color.hex);
    setCachedBackgroundColor(color.hex);
  };

  const handleTileSizeChange = (event) => {
    setTileSize(parseFloat(event.target.value, 10));
    setMainNodeSize(Math.min(mainNodeSize, tileSize*0.5));
  };

  const handleLineWidthChange = (event) => {
    setLineWidth(parseFloat(event.target.value));
    if (parseInt(event.target.value) == 0 && parseInt(mainNodeSize) == 0 && parseInt(nodeSize) == 0) {
      setBackgroundColor(mainColor);
    }
    if (event.target.value > 0 && backgroundColor === mainColor) {
      setBackgroundColor(cachedBackgroundColor);
    }
  };

  const handleMainNodeSizeChange = (event) => { 
    setMainNodeSize(Math.min(parseFloat(event.target.value, 10), tileSize*0.5));
    if (parseInt(event.target.value) == 0 && parseInt(lineWidth) == 0 && parseInt(nodeSize) == 0) {
      setBackgroundColor(mainColor);
    }
    if (event.target.value > 0 && backgroundColor === mainColor) {
      setBackgroundColor(cachedBackgroundColor);
    }
  };

  const handleNodeSizeChange = (event) => { 
    setNodeSize(Math.min(parseFloat(event.target.value, 10), tileSize*0.5));
    if (parseInt(event.target.value) == 0 && parseInt(lineWidth) == 0 && parseInt(mainNodeSize) == 0) {
      setBackgroundColor(mainColor);
    }
    if (event.target.value > 0 && backgroundColor === mainColor) {
      setBackgroundColor(cachedBackgroundColor);
    }
  };

  const handleTogglePicker = (picker) => {
    if (picker === currentPicker && isPickerVisible) {
      setIsPickerVisible(false);
    } else {
      setIsPickerVisible(true);
    }
    setCurrentPicker(picker);
    
  };
  
  const handleClosePicker = () => {
    setIsPickerVisible(false);
  };

  let td = new TileDimensions(tileSize);

  let mozaicContainerStyle = {
    backgroundColor: backgroundColor,
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    width: "100",
    height: "100vh",
    overflow: "hidden",
  };
  
  let rows = []

  let numRow = Math.floor(winHeight / td.height) + 2;
  let numCols = Math.floor(winWidth / td.widthHalf) + 1;

  for (let i = 0; i < numRow; i++) {
    rows.push(
      <EinsteinHexagon td={td} key={`hex${i}`} style={{top: `${i*(td.height+lineWidth)}px`}} mainColor={mainColor} secondaryColor={secondaryColor} backgroundColor={backgroundColor} lineWidth={lineWidth} mainNodeSize={mainNodeSize} nodeSize={nodeSize} isSideNodeToggled={isSideNodeToggled} isCornerNodeToggled={isCornerNodeToggled} randomizedCounter={randomizedCounter} resetCounter={resetCounter}/>
    )
  }

  let cols = [];

  for (let i = 0; i < numCols; i++) { 
    cols.push(
      <div key={`col${i}`} style={{position: "absolute", left: `${i*(td.widthThreeQuarter+lineWidth)}px`, marginLeft: -td.widthQuarter, marginTop: i%2===1 ? -(td.heightHalf+(lineWidth/2)) : 0, display: "flex", flexDirection: "column"}}>
        {rows}
      </div>
    )
  }

  let authorInfoTextStyle = {
    position: "fixed",
    bottom: "7px",
    right: "10px",
    color: "#333",
    transformOrigin: "bottom-right",
    fontSize: "0.8rem",
  }
  let lineLength = 1.3 * winWidth;
  let lineStyle = {
    position: "absolute", 
    display: "block",
    width: `${lineLength}px`,
    height: `${lineWidth}px`, 
    backgroundColor: backgroundColor, 
    transformOrigin: "left",
    marginLeft: `${-td.widthHalf}px`,
  }
  let lineStyleDown = {
    transform: "rotate(30deg)",
    marginTop: `${(-(td.height+lineWidth) * (Math.ceil(numCols/2)))}px`
  }
  let lineStyleUp = {
    transform: "rotate(-30deg)",
    marginBottom: `${-(td.height+lineWidth) * (Math.ceil(numCols/2))}px`
  }
  let lineStyleHorizontal = {
    position: "absolute", 
    display: "block",
    width: `${lineWidth}px`,
    height: `${winHeight}px`,
    backgroundColor: backgroundColor, 
    marginLeft: `${td.widthQuarter}px`,
  }

  let linesDown = [];
  let linesUp = [];
  let linesHorizontal = [];
  for (let i = 0; i < numCols; i++) {
    linesDown.push(
      <div key={`lineDown${i}`} style={{...lineStyle, ...lineStyleDown, top: `${i*(td.height+lineWidth) - ((lineWidth/2))}px`}}/>
    )
    linesUp.push(
      <div key={`lineUp${i}`} style={{...lineStyle, ...lineStyleUp, top: `${i*(td.height+lineWidth) - (lineWidth)}px`}}/>
    )
    linesHorizontal.push(
      <div key={`lineHorizontal${i}`} style={{...lineStyleHorizontal, left: `${i*(td.widthThreeQuarter+(lineWidth)) -(lineWidth/2)}px`}}/>
    )
  }

  const helpBtn = {
    position: "fixed",
    bottom: "15px",
    left: "15px",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "30px",
    padding: "5px",
    color: "#fff",
    fontSize: "1.5rem",
    backgroundColor: "#333334",
    cursor: "pointer",
    opacity: isHelpBtnHovered ? 1 : 0.6,
  }
  const helpContainerStyle = {
    position: "fixed", 
    bottom: "60px", 
    left: "15px", 
    backgroundColor: "#333334",
    color: "#fff",
    opacity: 0.9,  
    padding: "15px", 
    borderRadius: "5px", 
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    WebkitBoxShadow: "0 0 10px rgba(0,0,0,0.5)",
    MozBoxShadow: "0 0 10px rgba(0,0,0,0.5)",
  }
  const helpTitleStyle = {
    margin: "0 0 10px 0",
    fontSize: "1.2rem",
  }
  const helpMenuListStyle = {
    padding: "0px 25px",
  }
  const helpMenuListItemStyle = {
    marginBottom: "10px",
  }

  return (
    <div style={mozaicContainerStyle}>
      <div style={{marginLeft: `${-td.widthQuarter}px`, display: "flex", flexDirection: "row"}}>
        {cols}
      </div>
        {linesDown}
        {linesUp}
        {linesHorizontal}
      <TileMenu 
        mainColor={mainColor}
        secondaryColor={secondaryColor}
        backgroundColor={backgroundColor}
        tileSize={tileSize}
        onMainColorChange={handleMainColorChange}
        onSecondaryColorChange={handleSecondaryColorChange}
        onBackgroundColorChange={handleBackgroundColorChange}
        onTileSizeChange={handleTileSizeChange}
        onLineWidthChange={handleLineWidthChange}
        lineWidth={lineWidth}
        mainNodeSize={mainNodeSize}
        onMainNodeSizeChange={handleMainNodeSizeChange}
        nodeSize={nodeSize}
        onNodeSizeChange={handleNodeSizeChange}
        onTogglePicker={handleTogglePicker}
        isPickerVisible={isPickerVisible}
        currentPicker={currentPicker}
        onClosePicker={handleClosePicker}
        isSideNodeToggled={isSideNodeToggled}
        isCornerNodeToggled={isCornerNodeToggled}
        onSideNodeToggleChange={() => setIsSideNodeToggled(!isSideNodeToggled)}
        onCornerNodeToggleChange={() => setIsCornerNodeToggled(!isCornerNodeToggled)}
        onClickRandomize={()=> setRandomizedCounter(randomizedCounter+1)}
        onClickReset={()=> setResetCounter(resetCounter+1)}/>
      <h3 style={authorInfoTextStyle}>Created by Norbert Laszlo 2025</h3>
      <div>
        {isHelpMenuShowing && 
          <div style={helpContainerStyle}>
            <h4 style={helpTitleStyle}>Help</h4>
            <ul style={helpMenuListStyle}>
              <li style={helpMenuListItemStyle}>Click on the individual tiles to flip them.</li>
              <li style={helpMenuListItemStyle}>Use the draggable menu to change the colors and sizes of the tiles.</li>
              <li style={helpMenuListItemStyle}>Click on the randomize button to randomize the tiles.</li>
              <li style={helpMenuListItemStyle}>Click on the reset button to reset the tiles.</li>
              <li style={helpMenuListItemStyle}>Click on the question mark to show/hide this help menu.</li>
            </ul>
          </div>
        }
      </div>
      <div 
      style={helpBtn}
      onClick={() => setIsHelpMenuShowing(!isHelpMenuShowing)}
      onMouseEnter={() => setIsHelpBtnHovered(true)} 
      onMouseLeave={() => setIsHelpBtnHovered(false)} >?</div>
    </div>
  );
};

export default EinsteinTileLab;
