import React from "react";
import Fade from "react-reveal/Fade";
import LazyImg from "../../components/ui/LazyImg";
import geoGameWithTitle from "../../img/projects/geoGame/geoGameWithTitle.jpg";
import { Helmet } from "react-helmet";

import projects_list from "../../data/projectsData";

const project = projects_list[2];
const ProDuctive = () => {
  return (
    <section className="project-page page-section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Geo game</title>
        <link rel="canonical" href="http://norbertlaszlo.com/geogame" />
        <meta
          name="description"
          content="Ett online sällskapsspel med målet att gissa ursprunget eller platsen av det man ser på bilden varje runda. Spelaren med minst antal poäng i slutet vinner!"
        />
      </Helmet>
      <section className="header-section">
        <h1 className="project-title">{project.title}</h1>
        <p>{project.introText}</p>
      </section>
      <section className="split-section">
        <div className="main-content">
          <h2 className="project-subsection-title">...</h2>
          <p>...</p>
        </div>
        <div className="sub-content">
          <Fade right>
            <LazyImg
              image={{
                src: geoGameWithTitle,
                alt: "GeoGame",
              }}
              className="pro-ductive-img1"
              effect="blur"
            />
          </Fade>
        </div>
      </section>
      <section className="split-section">
        <div className="sub-content">
          <Fade left>
            <LazyImg
              image={{
                src: geoGameWithTitle,
                alt: "Pro-ductive app",
              }}
              className="pro-ductive-img2"
              effect="blur"
            />
          </Fade>
        </div>
        <div className="main-content">
          <h2 className="project-subsection-title">...</h2>
          <p>...</p>
        </div>
      </section>
    </section>
  );
};

export default ProDuctive;
