import React from "react";

const NotFound = () => {
  return (
    <div>
      <h1 className="">
        <i className="" /> Page Not Found
      </h1>
      <p className="">Sorry, this page does not exist</p>
    </div>
  );
};

export default NotFound;
