import React, { Fragment, useContext, useEffect } from "react";
import LazyImg from "../ui/LazyImg";
import pp from "../../img/pp-landing.jpg";
import mailIcon from "../../img/icons/mailWhite.png";
import linkedInIcon from "../../img/icons/linkedInWhite.png";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import { LayoutContext } from "../../routing/LayoutContext";
import closeIcon from "../../img/icons/exitWhite.png";
const ContactPopup = ({ isShowing }) => {
  const { state, dispatch } = useContext(LayoutContext);

  const hide = () => {
    dispatch({
      type: "TOGGLE_CONTACT_POPUP",
      payload: {
        isShowing: false,
      },
    });
  };

  useEffect(() => {
    if (state.isContactPopupShowing) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [state.isContactPopupShowing]);

  return (
    <Fragment>
      <Fade collapse when={state.isContactPopupShowing}>
        <div className="contact-popup-wrapper">
          <div className="popup-overlay" onClick={() => hide()}></div>
          <Bounce top opposite collapse when={isShowing}>
            <div className="contact-popup-container">
              <LazyImg
                image={{
                  src: closeIcon,
                  alt: "Close",
                }}
                onClick={() => hide()}
                className="contact-popup-close-img"
              />
              <div className="contact-pp-img-container">
                <div>
                  <LazyImg
                    image={{
                      src: pp,
                      alt: "Norbert Laszlo",
                    }}
                    effect="blur"
                  />
                </div>
              </div>
              <h1>Norbert Laszlo</h1>
              <ul>
                <li>
                  <LazyImg
                    image={{
                      src: mailIcon,
                      alt: "Email",
                    }}
                  />
                  hej@norbertlaszlo.com
                </li>
                <a
                  target="blank"
                  href="https://www.linkedin.com/in/norbert-laszlo/"
                  aria-label="LinkedIn sida"
                >
                  <li>
                    <LazyImg
                      image={{
                        src: linkedInIcon,
                        alt: "LinkedIn",
                      }}
                    />
                    LinkedIn sida
                  </li>
                </a>
              </ul>
            </div>
          </Bounce>
        </div>
      </Fade>
    </Fragment>
  );
};

export default ContactPopup;
