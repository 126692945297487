import React from "react";
import EinsteinTile from "./EinsteinTile";

const EinsteinHexagon = ({td, mainNodeSize, nodeSize, lineWidth, className, isSideNodeToggled, isCornerNodeToggled, mainColor, secondaryColor, backgroundColor, randomizedCounter, resetCounter, style = {}}) => {
    let tileOutlineStyles = [
      {
        clipPath: `polygon(
          ${td.widthQuarter}px 0, 
          ${td.widthHalf}px 0, 
          ${td.widthHalf}px ${td.heightHalf}px, 
          ${td.widthEight}px ${td.yCornerTop}px
        )`,
     },{
        clipPath: `polygon(
          ${td.widthHalf}px 0,
          ${td.widthThreeQuarter}px 0,  
          ${td.widthSevenEights}px ${td.yCornerTop}px,
          ${td.widthHalf}px ${td.heightHalf}px
        )`,
     },{
        clipPath: `polygon(
          ${td.widthSevenEights}px ${td.yCornerTop}px,
          ${td.width}px ${td.heightHalf}px,
          ${td.widthSevenEights}px ${td.yCornerBottom}px, 
          ${td.widthHalf}px ${td.heightHalf}px
        )`,
     },{
        clipPath: `polygon(
          ${td.widthSevenEights}px ${td.yCornerBottom}px, 
          ${td.widthThreeQuarter}px ${td.height}px,
          ${td.widthHalf}px ${td.height}px,
          ${td.widthHalf}px ${td.heightHalf}px
        )`,
    }, {
      clipPath: `polygon(
        ${td.widthHalf}px ${td.height}px,
        ${td.widthQuarter}px ${td.height}px,
        ${td.widthEight}px ${td.yCornerBottom}px, 
        ${td.widthHalf}px ${td.heightHalf}px
      )`,
  },{
      clipPath: `polygon(
        ${td.widthEight}px ${td.yCornerBottom}px, 
        0 ${td.heightHalf}px,
        ${td.widthEight}px ${td.yCornerTop}px,
        ${td.widthHalf}px ${td.heightHalf}px
      )`,
    }
    ];
    let hexaGroupContainerStyle = {
      position: "absolute",
      display: "block",
      padding: 0,
      margin: 0,
      width: `${td.width}px`,
      height: `${td.height}px`,
      clipPath: `polygon(
        ${td.widthQuarter}px 0,
        ${td.widthThreeQuarter}px 0,
        ${td.width}px ${td.heightHalf}px,
        ${td.widthThreeQuarter}px ${td.height}px,
        ${td.widthQuarter}px ${td.height}px,
        0 ${td.heightHalf}px
      )`
    };
    let tiles = [];
    
    tileOutlineStyles.forEach((style, index) => {
      tiles.push(
        <EinsteinTile style={style} key={`tile${index}`} mainColor={mainColor} secondaryColor={secondaryColor} randomizedCounter={randomizedCounter} resetCounter={resetCounter}/>
      );
    });
  
    let mainNodeStyle = {
      position: "absolute",
      width: `${mainNodeSize}px`,
      height: `${mainNodeSize}px`,
      backgroundColor: backgroundColor,
      left: `${td.widthHalf}px`,
      top: `${td.heightHalf}px`,
      borderRadius: "50%",
      transform: "translate(-50%, -50%)",
    };

    let subNodeStyle = {
        position: "absolute",
        width: `${nodeSize}px`,
        height: `${nodeSize}px`,
        backgroundColor: backgroundColor,
        borderRadius: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 10,
      };
    
      let margin = lineWidth/2;
    let nodeStyles = [
        {
            left: `${td.widthQuarter-margin}px`,
            top: `${0-margin}px`,
        },
        {
            left: `${td.widthHalf}px`,
            top: `${0-margin}px`,
        },
        {
            left: `${td.widthThreeQuarter+margin}px`,
            top: `${0-margin}px`,
        },
        {
            left: `${td.widthSevenEights+margin}px`,
            top: `${td.yCornerTop}px`,
        },
        {
            left: `${td.width+margin}px`,
            top: `${td.heightHalf}px`,
        },
        {
            left: `${td.widthSevenEights+margin}px`,
            top: `${td.yCornerBottom+margin}px`,
        },
        
        {
            left: `${td.widthThreeQuarter+margin}px`,
            top: `${td.height+margin}px`,
        },
        {
            left: `${td.widthHalf}px`,
            top: `${td.height+margin}px`,
        },
        {
            left: `${td.widthQuarter-margin}px`,
            top: `${td.height+margin}px`,
        },
        {
            left: `${td.widthEight-margin}px`,
            top: `${td.yCornerBottom+margin}px`,
        },
        {
            left: `${-margin}px`,
            top: `${td.heightHalf}px`,
        },
        {
            left: `${td.widthEight-margin}px`,
            top: `${td.yCornerTop}px`,
        },
    ];

    let nodes = [];
    nodeStyles.forEach((style, index) => {
        nodes.push(
            <div style={{...subNodeStyle, ...style, opacity: index % 2 === 0 ? (isCornerNodeToggled ? 1 : 0) : isSideNodeToggled ? 1 : 0}} key={`node${index}`}/>
        );
    });

    return (
      <div className={className} style={{...hexaGroupContainerStyle, ...style}}>
        {tiles}
        <div style={mainNodeStyle}/>
        {nodes}
      </div>
    )
  }

export default EinsteinHexagon