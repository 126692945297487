import React, {  useContext } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import LazyImg from "../components/ui/LazyImg";
import { Helmet } from "react-helmet";

import ContactButton from "../components/ui/ContactBtn";
import { useWindowDimensions } from "../inc/WindowDimensions";
import TimelineItem from "../components/ui/TimelineItem";
import tl_exp_list from "../data/tlExp";

import pp from "../img/pp-landing.jpg";
import cv_img from "../img/cv-img.png";

import projects_list from "../data/projectsData";

import { LayoutContext } from "../routing/LayoutContext";

/*
var floatingPhrases = [
  "Data science",
  "AI",
  "Machine Learning",
  //"Programming",
  //"Tech",
  "SQL",
  "CSS",
  "React Native",
  "HTML",
  "PHP",
  "Python",
  "Java",
  "C#",
  "React",
  "Big Data",
  //"Maths",
  "Science",
  //"Git",
  //"Software",
  "Neural Networks",
  "NLP",
  "Reinforcement Learning",
  "Data Engineering",
  "Software Engineering",
  //"Angular",
  //"Developer",
  "Java Script",
  "Type Script",
  "Web Development",
  "App Development",
  "Digital Design",
  //"Time Series",
  //"Forecasting Models",
  "Algorithms",
  //"Image To Text",
  //"Probabilistic ML",
  "Data analysis",
];
*/
/*
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
function makeArr(startValue, stopValue, cardinality) {
  var arr = [];
  var step = (stopValue - startValue) / (cardinality - 1);
  for (var i = 0; i < cardinality; i++) {
    arr.push(startValue + step * i);
  }
  return arr;
}
var linSpace_distance = shuffle(
  makeArr(0.3, 0.98, Math.floor(floatingPhrases.length / 2)).concat(
    makeArr(-0.98, -0.3, Math.floor(floatingPhrases.length / 2))
  )
);
var linSpace_offset = shuffle(makeArr(0, 3, floatingPhrases.length));
var floatingPhraseObjects = [];
floatingPhrases.map((p, index) =>
  floatingPhraseObjects.push({
    id: index,
    phrase: p,
    dist: linSpace_distance[index],
    offSet: linSpace_offset[index],
  })
);*/
//floatingPhrases = [];
export const Landing = () => {
  let winWidth = useWindowDimensions().width;
  //let winHeight = useWindowDimensions().height;
  //let floatWidth = winWidth / 2 - 30;
  //let floatHeight = winHeight / 2;
  //const [angleValue, setAngleValue] = useState(1);
  const { dispatch } = useContext(LayoutContext);
  const showContactPopup = (e) => {
    console.log("handler");
    dispatch({
      type: "TOGGLE_CONTACT_POPUP",
      payload: {
        isShowing: true,
      },
    });
  };

  /*useEffect(() => {
    setTimeout(() => {
      setAngleValue((angle) => angle + 0.0005);
    }, 10);
  }, [angleValue]);
*/
  return (
    <div className="landing-master-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Norbert Laszlo</title>
        <link rel="canonical" href="http://norbertlaszlo.com/" />
        <meta
          name="description"
          content="Välkommen till min portfoliohemsida! Se några av projekten som jag jobbat på under åren, min bakgrund och det mest aktuella jag jobbar på."
        />
      </Helmet>

      <div className="dynamic-bkg">
        {/*
        <div className="floating-text-center">
          {floatingPhraseObjects.map((obj, index) => (
            <FloatingText
              key={index}
              text={obj.phrase}
              x={
                (Math.cos(angleValue * (obj.dist ^ 2) * 0.2 + obj.offSet) % 1) *
                obj.dist *
                floatWidth
              }
              y={
                (Math.sin(angleValue * (obj.dist ^ 2) * 0.2 + obj.offSet) % 1) *
                obj.dist *
                floatHeight
              }
            />
          ))}
        </div>*/}
        <div className="landing-blur-overlay" />
        <div className="landing-intro-container">
          <div className="landing-link-wrapper">
            <Link
              to={"/projects"}
              aria-label="Projekt"
              className="landing-link landing-link-level1"
            >
              Projekt
            </Link>
            <Link to={"/cv"} aria-label="CV" className="landing-link">
              CV
            </Link>
            <Link
              to={"/timeline"}
              aria-label="Tidslinje"
              className="landing-link landing-link-level1"
            >
              Tidslinje
            </Link>
          </div>
          <div className="landing-intro-pp-border">
            <LazyImg
              image={{
                src: pp,
                alt: "Norbert Laszlo",
              }}
              className="landing-intro-pp"
              effect="blur"
            />
          </div>
        </div>
      </div>
      <div className="landing-swirrl-container">
        <svg
          width="100%"
          height="50"
          className="landing-svg-container landing-svg-container-top"
        >
          <path
            d={`M ${winWidth} 50 L ${winWidth} 40 C ${
              winWidth - winWidth / 4
            } -50 ${winWidth / 4} 100 -1 0 V 50 M 0 12 M -1 10 M -1 15`}
            className="landing-svg-path"
          />
        </svg>
        <div className="landing-intro-wrapper">
          <section className="landing-intro-section page-section">
            <div className="landing-intro-conatiner">
              <h2 className="landing-intro-subtitle">
                M.SC. IN DATA SCIENCE & AI </h2>
              <h2 className="landing-intro-subtitle">
                B.S IN SOFTWARE ENGINEERING
              </h2>
              <p className="landing-intro-p">
                <b>Välkommen till min portfoliohemsida!</b> <br /> Här visar jag
                upp några av de projekt som jag jobbat på under åren,
                presenterar min bakgrund och delar med mig av det mest aktuella
                jag jobbar på. Har du några frågor eller bara vill ta ett gött
                snack, tveka inte att kontakta mig!
              </p>
            </div>
          </section>
        </div>
        <svg
          width="100%"
          height="60"
          className="landing-svg-container landing-svg-container-bottom"
        >
          <path
            d={`M ${winWidth} 0 L ${winWidth} 60 C ${
              winWidth - winWidth / 4
            } 20 ${winWidth / 5} 20 -1 40 V 0 M 0 12 M -1 10 M -1 15`}
            className="landing-svg-path"
          />
        </svg>
      </div>
      <section className="landing-contact-section page-section">
        <h3>Ta kontakt med mig</h3>
        <ContactButton onClick={showContactPopup} />
      </section>
      <section className="landing-category-section page-section">
        <Link to={"/cv"} aria-label="CV" className="landing-category-link">
          CV
        </Link>
        <Fade bottom>
          <Link to={"/cv"} aria-label="CV">
            <LazyImg
              image={{
                src: cv_img,
                alt: "CV",
              }}
              className="landing-cv-img"
              effect="blur"
            />
          </Link>
          <Link
            to={"/cv"}
            aria-label="Se hela CV:t"
            className="landing-cv-link"
          >
            Se hela CV:t
          </Link>
        </Fade>
      </section>

      <section className="landing-category-section page-section">
        <Link
          to={"/projects"}
          aria-label="Projekt"
          className="landing-category-link"
        >
          Projekt
        </Link>
        <div className="landing-project-container">
          <div>
            <div className="landing-project-square landing-project-square-top-right">
              <h3>{projects_list[2].title}</h3>
              <p>
                {projects_list[2].introText} ...
                <Link
                  to={"/projects/" + projects_list[2].route}
                  aria-label={projects_list[2].title}
                  className="landing-project-inline-link"
                >
                  Läs mer
                </Link>
              </p>
            </div>
            <Fade right>
              <div className="landing-project-img-container">
                <LazyImg
                  image={{
                    src: projects_list[2].img,
                    alt: "Project",
                  }}
                  effect="blur"
                />
              </div>
            </Fade>
          </div>
          <section className="landing-project-divider" />
          <div>
            <Fade left>
              <div className="landing-project-img-container">
                <LazyImg
                  image={{
                    src: projects_list[0].img,
                    alt: "Project",
                  }}
                  effect="blur"
                />
              </div>
            </Fade>
            <div className="landing-project-square">
              <h3>{projects_list[0].title}</h3>
              <p>
                {projects_list[0].introText}..{" "}
                <Link
                  to={"/projects/" + projects_list[0].route}
                  aria-label={projects_list[0].title}
                  className="landing-project-inline-link"
                >
                  Läs mer
                </Link>
              </p>
            </div>
          </div>
          <section className="landing-project-divider" />
          <div>
            <div className="landing-project-square landing-project-square-bottom-right">
              <h3>{projects_list[1].title}</h3>
              <p>
                {projects_list[1].introText}..{" "}
                <Link
                  to={"/projects/" + projects_list[1].route}
                  aria-label={projects_list[1].title}
                  className="landing-project-inline-link"
                >
                  Läs mer
                </Link>
              </p>
            </div>
            <Fade right>
              <div className="landing-project-img-container">
                <LazyImg
                  image={{
                    src: projects_list[1].img,
                    alt: "Project",
                  }}
                  effect="blur"
                />
              </div>
            </Fade>
          </div>
        </div>
        <Link
          to={"/projects"}
          aria-label="Se alla projekt"
          className="landing-projects-link"
        >
          Se alla projekt
        </Link>
      </section>
      <section className="landing-category-section page-section">
        <Link
          to={"/timeline"}
          aria-label="Tidslinje"
          className="landing-category-link"
        >
          Tidslinje
        </Link>
        <Fade bottom>
          <div className="tl-header">
            <h2 className="current-year">2022</h2>
          </div>
          <div className="tl-main-container-landing">
            {winWidth > 600 && (
              <div className="tl-left-container">
                {tl_exp_list
                  .slice(0, 2)
                  .map(
                    (item, index) =>
                      index % 2 === 0 && (
                        <TimelineItem
                          key={index + "left"}
                          leftSide={true}
                          year={item.year}
                          title={item.title}
                          text={item.text}
                        />
                      )
                  )}
              </div>
            )}
            <div className="tl-line">
              <div className="tl-line-crown"></div>
              <div className="tl-line-dashed"></div>
            </div>
            <div className="tl-right-container">
              {tl_exp_list
                .slice(0, 2)
                .map(
                  (item, index) =>
                    (winWidth <= 600 || index % 2 === 1) && (
                      <TimelineItem
                        key={index + "right/main"}
                        leftSide={false}
                        year={item.year}
                        title={item.title}
                        text={item.text}
                      />
                    )
                )}
            </div>
          </div>
          <Link
            to={"/timeline"}
            aria-label="Se hela tidslinjen"
            className="landing-timeline-link"
          >
            Se hela tidslinjen
          </Link>
        </Fade>
      </section>
    </div>
  );
};
export default Landing;
