import CV from "./CV-2023-Norbert_Laszlo-ENG-L.pdf";

const getDoc = (docName) => {
  docName = docName.toLowerCase();
  docName = docName.replace(":", "");
  switch (docName) {
    case "cv":
      return CV;
    default:
      return null;
  }
};

export { getDoc };
