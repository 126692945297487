import React, { createContext, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { LayoutReducer } from "./LayoutReducer";
import ContactPopup from "../components/layout/ContactPopup";
import Footer from "../components/layout/Footer";
import Navbar from "../components/layout/Navbar";

export const LayoutContext = createContext();

const layoutInitState = {
  isContactPopupShowing: false,
};

export const LayoutContextProvider = (props) => {
  const [state, dispatch] = useReducer(LayoutReducer, layoutInitState);
  const location = useLocation();
  const exeptionsNavbar = ["/einsteinTileLab"];
  const exeptionsFooter = ["/einsteinTileLab"]
  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      <ContactPopup isShowing={state.isContactPopupShowing} />
      {!exeptionsNavbar.includes(location.pathname) && <Navbar />}
      {props.children}
      {!exeptionsFooter.includes(location.pathname) && <Footer />}
    </LayoutContext.Provider>
  );
};
