import React, { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routing/AppRoutes";
import "./style/App.css";
import "./style/Navbar.css";
import "./style/Document.css";
import "./style/Landing.css";
import "./style/Timeline.css";
import "./style/Projects.css";
import "./style/EinsteinTile.css";
const App = () => {
  return (
    <Fragment>
      <Router>
        <AppRoutes />
      </Router>
    </Fragment>
  );
};

export default App;
