import React from "react";
import NameBounce from "../components/ui/NameBounce";
import ProjectItem from "../components/ui/ProjectItem";
import projects_list from "../data/projectsData";
import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Projects = () => {
  return (
    <section className="page-section projects-section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Projekt</title>
        <link rel="canonical" href="http://norbertlaszlo.com/projects" />
        <meta
          name="description"
          content="Mina projekt. Pro-ductive - Todo & Habit tracker / WebbCV - Det nya CV:t / GeoGame."
        />
      </Helmet>
      <NameBounce text={"Norbert Laszlo"} />
      <ProjectItem
        route={projects_list[2].route}
        title={projects_list[2].title}
        introText={projects_list[2].introText}
        sliderImages={projects_list[2].slideShow}
      ></ProjectItem>
      <ProjectItem
        route={projects_list[0].route}
        title={projects_list[0].title}
        introText={projects_list[0].introText}
        sliderImages={projects_list[0].slideShow}
      ></ProjectItem>
      <ProjectItem
        route={projects_list[1].route}
        title={projects_list[1].title}
        introText={projects_list[1].introText}
        sliderImages={projects_list[1].slideShow}
      ></ProjectItem>
    </section>
  );
};

export default Projects;
