// https://www.pluralsight.com/guides/how-to-get-a-component-from-anywhere-in-an-app-using-react
export const LayoutReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_CONTACT_POPUP":
      return {
        isContactPopupShowing: action.payload.isShowing,
      };

    default:
      return state;
  }
};
